<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="text-center">
                <h2>Whoops! Page is not found!</h2>
            </v-col>
        </v-row>
        <v-row justify="space-around" align="start">
            <v-col cols="12" md="6" class="text-center">
                Here's a cute cat instead.<br>
                <img src="/assets/images/cutecat.gif">
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import search_results from '@/store/sample_data/data_SearchResults.js'

export default {
    data() {
        return {
            search: '',
        }
    },
    computed: {

    },
    components: {

    }
}

</script>
<style>
</style>
