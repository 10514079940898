<template>
    <v-container fluid class="site-width" :class="isMobileView && 'px-1'">
		<v-card light min-width="100%" :loading="loading">
			<div :class="isMobileView ? 'px-3 py-5' : 'px-5 pt-5 pb-8'">
				<h3 class="py-2">Search Results: {{ current_keyword }}</h3>
				<div class="search-row">
					<div class="search-col-left">
						<div class="search-wrapper">
							<v-text-field class="search-input" label="Search terms" ref="searchfield" v-model="keywords" v-on:keyup="performSearch" filled hide-details />
							<div class="search-actions__wrapper">
								<v-btn large depressed color="primary" dark @click="doSearch" class="px-3">
									Search<v-icon small right>fas fa-search</v-icon>
								</v-btn>
								<v-btn class="px-3" large depressed outlined color="#254485" @click="loadIndex">
									View A-Z Index
								</v-btn>
							</div>
						</div>

						<div class="search-filters__wrapper" :class="isMobileView && 'flex-column align-start'">
                            <p class="search-filters__label">Filter Results:</p>
                            <QSearchFilters v-model="searchCategories" :class="isMobileView && 'flex-column'" />
						</div>

						<template v-if="!search_dialog && !loading">
							<v-list two-line v-if="pagedResults.length > 0">
								<v-list-item class="search-result" v-for="(result, k) in pagedResults" :key="k" @click="loadPage(result)">
									<v-list-item-content>
										<v-list-item-title>
											<span class="q-subtitle">{{ getCategoryName(result) }}:</span><br><strong class="q_blue_1--text">{{ getTitle(result) }}</strong>
										</v-list-item-title>
										<v-list-item-subtitle>{{ getDescription(result) }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-icon small v-if="(result.external_url && result.external_url !== 'internal') || result.IsExternalResource">fas fa-external-link-alt</v-icon>
									</v-list-item-action>
								</v-list-item>
							</v-list>
							<h5 v-else class="text-h6 mt-8">Sorry, no results!</h5>
							<v-pagination v-if="pagedResults.length > 0" v-model="page" :length="total_pages" :total-visible="7" color="q_blue_1" class="mt-4" />
						</template>
					</div>

					<div class="search-col-right">
						<v-card outlined class="submit-ticket-card">
							<div class="icon-wrapper">
								<v-icon x-large color="white">icon-q-support</v-icon>
							</div>
							<h5 class="text-center mb-0">Didn't find what you were looking&nbsp;for?</h5>
							<v-btn small depressed color="q_blue_1" dark to="/support#step3" class="submit-ticket-btn">
								Submit a support ticket
							</v-btn>
						</v-card>
					</div>
				</div>
			</div>
		</v-card>
    </v-container>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import QSearchFilters from '@/components/utils/QSearchFilters.vue'

export default {
	components: {
		QSearchFilters,
    },
    data () {
        return {
            itemsPerPage: 10,
            keywords: null,
            page: 1,
			lastPage: 1,
            pagedResults: [],
            current_keyword: null,
            all_results: [],
			searchCategories: {
				Agent: true,
				AgentDocument: true,
				Page: true,
			},
        }
    },
    mounted () {
		this.parseRouteQuery()
        if (this.keywords) {
            this.loadData()
        }
    },
    computed: {
        total_pages () {
			return this.lastPage
        },
        loading () {
            return this.$store.state.entities.search_result.loading
        },
        search_dialog () {
            return this.$store.state.show_search
        },
		searchCategoriesFilter () {
			return Object.entries(this.searchCategories)
				.filter(([_, value]) => value)
				.map(([key]) => key)
				.join(',')
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
    },
    watch: {
        page () {
            const query = { keyword: this.keywords, searchModels: this.searchCategoriesFilter, page: this.page }
            this.$router.push({ query })
        },
        '$route.query' () {
			this.parseRouteQuery()
            if (this.keywords) {
                this.loadData()
            } else {
				this.resetResults()
			}
        },
		searchCategoriesFilter () {
			const query = { keyword: this.keywords, searchModels: this.searchCategoriesFilter, page: 1 }
			this.$router.push({ query })
		},
    },
    methods: {
		parseRouteQuery () {
			this.keywords = this.$route.query.keyword
			this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
			this.current_keyword = this.keywords
			const searchCategoriesString = this.$route.query.searchModels
			if (searchCategoriesString) {
				const categoryItems = searchCategoriesString.split(',')
				Object.keys(this.searchCategories).forEach((key) => {
					if (categoryItems.includes(key)) {
						this.searchCategories[key] = true
					} else {
						this.searchCategories[key] = false
					}
				})
			}
		},
        loadPage (result) {
            if (result.IsExternalResource || result.Model === 'AgentDocument') {
                // TODO: implement view component for result.Model === 'AgentDocument' URL
                window.open(result.URL, '_blank')
            } else {
                const path = result.URL.replace(/^(([^:/?#]+):)?(\/\/([^/?#]*))/g, '')
                this.$router.push(path)
            }
        },
        loadData: debounce(function() {
			const options = {
				search: this.keywords,
				itemsPerPage: this.itemsPerPage,
				page: this.page,
			}
			const filters = { searchModels: this.searchCategoriesFilter }
            QuilityAPI.getElasticSearchResults(filters, options)
                .then(json => {
					if (json.error) {
						this.showError('Opps, something went wrong.<br>', json.message)
						this.resetResults(true)
					} else {						
						this.current_keyword = this.keywords
                        this.all_results = json.data
                        this.pagedResults = this.all_results
                        this.lastPage = json.last_page
						this.trackSearchResults()
					}
                })
				.catch((err) => {
					if (err.message === 'Aborted') { return }
					console.error(err)
					this.showError('Opps, something went wrong.<br>', err.message)
					this.resetResults(true)
				})
        }, 200),
        trackSearchResults () {
            gtag('event', 'search', {
                'event_category': 'SearchResultsPage',
                'event_label': this.keywords,
            })
        },
        performSearch (e) {
            if (e.keyCode === 13) {
                this.doSearch()
            }
        },
		doSearch () {
			const query = { keyword: this.keywords, searchModels: this.searchCategoriesFilter, page: 1 }
			this.$router.push({ query })
		},
        getCategoryName (result) {
            switch (result.Model) {
                case 'AgentDocument': { return 'Document' }
                case 'Agent': { return result.LeadershipLevel }
                case 'Page': { return 'Page' }
                default: { return this.parseCategoryName(result) }
            }
        },
        parseCategoryName(result) {
            if (result.URL.includes('/document/')) {
                return 'Document'
            }
            if (result.URL.includes('/agents/')) {
                return 'Agent'
            }
            return 'Page'
        },
        getTitle (result) {
			return result.Title
        },
        getDescription (result) {
			return result.Description
        },
		resetResults (resetPage) {
			if (resetPage) {
				this.page = 1
			}
			this.lastPage = 1
			this.all_results = []
			this.pagedResults = []
		},
		loadIndex () {
            this.$router.push('/site-index')
            this.search = false
        },
    },
}
</script>

<style lang="scss" scoped>
.search-row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 4rem 3rem;

	.search-col-left {
		max-width: 100%;
		flex: 5 5 600px;
	}

	.search-col-right {
		max-width: 100%;
		flex: 1 1 280px;
	}
}

.search-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem 2rem;
}

.search-input {
	flex: 10 1 300px;

	::v-deep .v-input__slot {
		min-height: 44px !important;
		height: 44px;
	}

	&.v-text-field--filled:not(.v-text-field--single-line) ::v-deep input {
		padding: 6px 0;
		margin-top: 16px;
		height: 28px;
	}

	&.v-text-field--filled ::v-deep .v-label {
		top: 16px;
	}

	&.v-text-field--filled ::v-deep .v-label--active {
		transform: translateY(-12px) scale(0.75);
	}
}

.search-actions__wrapper {
	flex: 1 1 280px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem 0.5rem;
}

.search-filters__wrapper {
	margin: 1.5rem 0 2.5rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5rem 1.5rem
}

.search-filters__label {
	margin-bottom: 0;
	font-size: 0.875rem;
	font-weight: 600;
}

.submit-ticket-card {
	padding: 1rem 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 1rem;

	.icon-wrapper {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50% !important;
		background-color: #0083eb;
	}
}

.submit-ticket-btn {
	&:hover {
		color: #fff;
	}
}

.v-list-item__title .q-subtitle{
	font-size: 0.75rem;
	font-weight: 100;
}
</style>