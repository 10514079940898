<template>
    <v-row>
        <v-col cols="12" justify="center">
            <v-card>
                <v-card-title>
                    <h5 class="text-center">Linking...<br>
                        <v-progress-circular small color="blue" indeterminate></v-progress-circular>
                    </h5>
                    {{status}}
                    {{err}}
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import auth0 from 'auth0-js';
import QuilityAPI from "@/store/API/QuilityAPI.js"
export default {
    name: "QApiTest",
    props: [],
    data() {
        return {
            webAuth: {},
            primary_token: null,
            err: null,
            sub: this.$auth.auth_user.sub,
            status: "One sec, looking for my mushroom powder...",
            magic_steps: [
                "Step 12, 2, 3...",
                "Found it, combining ingredients...",
                "Re-reading instructions...",
                "Adding a pinch of developer tears...",
                "Merging profiles...",
                "Finishing up...",
            ],
            step_counter: 0,
        }
    },
    mounted: function() {
        var g = this
        var g = this
        var opts = {
            audience: [process.env.VUE_APP_AUTH0_MANAGEMENT_AUDIENCE],
            scope: 'openid email profile scope: read:current_user update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata',
            redirect_uri: process.env.VUE_APP_BASE_URL + '/admin/linkaccount'
        };
        this.sub = this.$auth.user.sub
        this.$auth.getTokenSilently(opts).then(function(token) {
            g.mergeMetadata(function() {
                g.$router.push('/admin/linkaccounts/')
            })
        })
    },
    computed: {

    },
    methods: {
        mergeMetadata: function(callback) {
            var g = this
            this.$auth.getTokenSilently().then(function(primary_token) {
                var secondary_tokens = g.getTokensFromHash()
                var webAuth = new auth0.WebAuth({
                    domain: process.env.VUE_APP_AUTH0_DOMAIN,
                    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID
                });
                webAuth.client.userInfo(secondary_tokens.access_token, function(err, user_2) {
                    g.nextMagicStep();
                    if (err) {
                        g.showError("Problem with secondary account... is it already linked?");
                        callback()
                    }
                    //lets use the laravel api for this...
                    //we need both user_ids and both access tokens.
                    var payload = {
                        user_1: {
                            access_token: primary_token,
                            sub: g.$auth.auth_user.sub
                        },
                        user_2: {
                            access_token: secondary_tokens.access_token,
                            id_token: secondary_tokens.id_token,
                            sub: user_2.sub
                        }
                    }
                    setInterval(function() {
                        g.nextMagicStep();
                    }, 2000)
                    QuilityAPI.mergeMetadata(payload).then(function() {
                        callback()
                    })
                });
            }).catch(function(err) {
                g.showError("Problem with account token.");
                //g.$router.push('/admin/linkaccounts/')
            });
            return
        },
        linkAccount: function(primary_token, sub) {
            var g = this;
            var secondary_tokens = this.getTokensFromHash()

            var auth0Manage = new auth0.Management({
                domain: process.env.VUE_APP_AUTH0_DOMAIN,
                token: primary_token
            });

            auth0Manage.linkUser(sub, secondary_tokens.id_token, function(err, a) {
                if (err) {
                    g.showError("Problem with secondary account... is it already linked?");
                }
            });
            this.$router.push('/admin/linkaccounts/')
            return;
        },
        getTokensFromHash() {
            this.nextMagicStep();
            var access_token;
            var id_token;
            var code
            var vars = location.hash.replace('#', '').split('&');
            for (var i = 0; i < vars.length; i++) {
                var v = vars[i];
                if (v.indexOf('id_token=') > -1) {
                    id_token = v.replace('id_token=', '');
                }
                if (v.indexOf('access_token=') > -1) {
                    access_token = v.replace('access_token=', '');
                }
                if (v.indexOf('code=') > -1) {
                    code = v.replace('code=', '');
                }
            }
            return {
                'access_token': access_token,
                'id_token': id_token,
                'code': code
            }
        },
        nextMagicStep() {
            this.status = this.magic_steps[this.step_counter];
            this.step_counter = this.step_counter + 1;
        }
    }
};

</script>
<style scoped>
</style>
